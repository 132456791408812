
.payment-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


@media (min-width: 992px) and (orientation: landscape) {
  .payment-options {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
  }
}
