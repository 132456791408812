:root {
  --hex-drawer-height: 90vh;
}

.active:focus,
.active:focus-within {
  background-color: #00356b;
  color: #ffffff;
  margin-top: 10px;
}
.active {
  background-color: #00356b;
  color: #ffffff;
  margin-top: 10px;
}
.close-btn:hover {
  border-color: red !important;
}

.action-button:hover {
  background-color: #2b2f32 !important;
}

.utme_slip,
.bank_slip,
.sse_result,
.passport_photo,
.lg_origin,
.stamped_envelop,
.nin_slip,
.birth_cert {
  display: none !important;
}
