


@media all {

    .page-break {
        display: none;
    }

}


@media print {
    html, body {
        height: 100vh; /* Use 100% here to support printing more than a single page*/
        margin: 0 !important;
        padding: 0 !important;
    }

    .page-break {
        margin-top: 1rem;
        margin-bottom: 5rem;
        display: block;
        page-break-before: auto
    }

}
