@font-face {
  font-family: Roboto;
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype'),
  url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype'),
  url("assets/fonts/Roboto/Roboto-Light.ttf") format('truetype');
}

@font-face {
  font-family: Inter;
  src: url('assets/fonts/Inter/static/Inter-Regular.ttf') format('truetype'),
  url('assets/fonts/Inter/static/Inter-Light.ttf') format('truetype'),
  url("assets/fonts/Inter/static/Inter-Bold.ttf") format('truetype');
}


@font-face {
  font-family: Mulish;
  src: url('assets/fonts/Mulish/static/Mulish-Regular.ttf') format('truetype'),
  url('assets/fonts/Mulish/static/Mulish-Bold.ttf') format('truetype'),
  url("assets/fonts/Mulish/static/Mulish-Light.ttf") format('truetype');
}


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, Inter, Mulish, "Helvetica Neue", sans-serif;
}
