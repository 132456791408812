.active-tab{
  background-color: #ffffff!important;
  color: #3e4095!important;
  border-bottom: none !important;
}
.tab{
  background-color: #aaaaaa;
  color: #000000;
  border: solid 1px #000000;
}

#root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-a43vq2-MuiContainer-root > div.MuiBox-root.css-nvuhze > div.MuiBox-root.css-5dt5vx > div > div{
  overflow: scroll!important;
}

@media screen and (min-width: 992px) {
  #root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-a43vq2-MuiContainer-root > div.MuiBox-root.css-nvuhze > div.MuiBox-root.css-5dt5vx > div > div{
    overflow: hidden!important;
  }
}
